<template>
  <div class="carbon">
    <page-nav title="碳达峰碳中和" />
    <img class="banner" src="https://etb618.oss-cn-beijing.aliyuncs.com/article/news/news_bg.jpg" />
    <div class="content">
      <h2>项目背景</h2>
      <img class="img" src="../assets/tdf_xmbj_img.jpg" />
      <div class="desc">
        <p>2020年9月30日，习近平主席在联合国生物多样性峰会上的讲话：中国将秉持人类命运共同体理念，继续作出艰苦卓绝努力，提高国家自主贡献力度，采取更加有力的政策和措施，二氧化碳排放力争于2030年前达到峰值，努力争取2060年前实现碳中和，为实现应对气候变化《巴黎协定》确定的目标作出更大努力和贡献。</p>
        <p>2020年12月12日，习近平主席在气候雄心峰会的讲话提出中提出：到2030年，中国单位国内生产总值二氧化碳排放将比2005年下降65%以上，非化石能源占一次能源消费比重将达到25%左右，森林蓄积量将比2005年增加60亿立方米，风电、太阳能发电总装机容量将达到12亿千瓦以上。</p>
        <p>“碳达峰”与“碳中和”无疑是2021年全国两会最大的热点，政府工作报告将“做好碳达峰、碳中和工作”列为2021年重点任务之一；“十四五”规划也将加快推动绿色低碳发展列入其中。以“碳达峰”“碳中和”目标，加强技术创新，方可指引并实现高质量发展，为企业抓住未来市场机遇。</p>
      </div>
    </div>
    <div class="news">
      <h2>新闻资讯</h2>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="fetchList"
      >
        <van-cell v-for="item in list" :key="item.id" :title="item.title" :label="item.createTime" :to="'/article?id=' + item.id" />
      </van-list>
    </div>
    <GoTop />
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast, Cell } from 'vant';
import Fetch from '@/common/fetch';
import { GetArticleList } from '@/common/interface';
import PageNav from '../components/PageNav.vue';
import GoTop from '../components/GoTop.vue';

Vue.use(Toast);
Vue.use(Cell);

export default {
  name: 'Carbon',
  components: { PageNav, GoTop },
  data() {
    return {
      curPage: 1,
      loading: false,
      finished: false,
      list: [],
    };
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    fetchList() {
      const page = this.curPage;
      const params = {
        pageNum: page,
        pageSize: 20,
      };

      Fetch.post(GetArticleList, params).then((res) => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = page == 1 ? resList : this.list.concat(resList);
        this.curPage = page + 1;
        this.list = newList;
        this.loading = false;
        this.finished = finished;
      }).catch((err) => {
        Toast(err.msg || '请求失败');
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.carbon {
  .banner {
    display: block;
    width: 375px;
    height: 150px;
  }
  .content {
    position: relative;
    margin: 15px 0;
    padding: 0 16px;
    h2 {
      font-size: 18px;
      text-align: center;
      position: absolute;
      top: 20px;
      left: 0;
      width: 100%;
    }
    .img {
      width: 100%;
    }
    .desc {
      font-size: 14px;
      font-family: '微软雅黑';
      color: #333333;
      text-indent: 2em;
      line-height: 1.3;
      margin: 1em 0;
      p {
        margin-bottom: 1em;
      }
    }
  }
  .news {
    h2 {
      font-size: 18px;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 15px;
    }
  }

}


</style>

